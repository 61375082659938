<div
  class="modal fade theme-popup"
  id="update-compliance-status-popup"
  [class.show]="showModal"
  [style.display]="showModal ? 'block' : 'none'"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered"  style="max-width: 1100px; width: 100%">
    <div class="modal-content">
      <div class="modal-header sticky-top">
        <h5 class="modal-title" id="staticBackdropLabel">{{title | translate}}</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="requestCloseModal()">
        </button>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
@if(showModal){
  <div class="modal-backdrop fade show"></div>
}
