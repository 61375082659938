import { Component, EventEmitter, Input, Output, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-details-modal',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './details-modal.component.html',
  styleUrl: './details-modal.component.scss',
})
export class DetailsModalComponent {
  @Input() title: string;
  @Input() showModal: boolean;
  @Output() closeModalRequest = new EventEmitter<any>();

  requestCloseModal() {
    this.closeModalRequest.emit();
  }
}
